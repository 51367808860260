import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToHashElement() {
  const location = useLocation();
  
  useEffect(() => {
    const hash = location.hash.slice(1);
    if (hash) {
      document.getElementById(hash)?.scrollIntoView({
        behavior: 'smooth',
        // inline: 'nearest',
      });
      console.log("hash :" + hash);
    } else {
      window.scrollTo(0, 0);
    }
  }, [location.hash]);

  return null;
}
