import React, {useEffect , useState} from "react";
import '../css/about.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, EffectCoverflow, Pagination , Navigation } from 'swiper/modules';

function About(props) {

  const [isShowSJ,setIsShowSJ ] = useState(false);
  const [isShowCH,setIsShowCH ] = useState(false);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
	}, []);


  return (
    <>
      <section className="AboutSetion">
        <div className="AboutSetionWrap">
          <div className="SectionHeaderWrap">
            {/* <h2></h2> */}
            <div className="BGPattern100"></div>
            <div className="MovieScreen"></div>
          </div>
          <div className="About">
            <div className="AboutWrap">
              <div className="TextWrap">
                {/* <h2>{props.translation('about_actgames_intro')}</h2> */}
                <h5>{props.translation('about_header_text')}</h5>
                <p>{props.translation('about_actgames_intro_dec')}</p>
              </div>
            </div>
          </div>

          <div className="History">
            <div className="HistoryWrap">
              <h4>{props.translation('about_actgames_history')}</h4>
              <Swiper
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={'auto'}
                coverflowEffect={{
                  rotate: 45,
                  stretch: 0,
                  depth: 290,
                  modifier: 2,
                  slideShadows: false,
                }}
                navigation={true}
                pagination={true}
                modules={[EffectCoverflow, Pagination, Autoplay, Navigation]}
                className="HistorySwiper"
              >
                <SwiperSlide>
                  <div className="YearWrap">
                    <div className="YearContent">
                      <div className="TextWrap">
                        <h1>2023</h1>
                        <ul>
                          <li><span>07</span>{props.translation('about_actgames_history2023_07')}</li>
                        </ul>
                      </div>
                    </div>
                    <div className="YearImg">
                      <img className="Widthimg" src="https://data.actgames.co.kr/actgames/images/about/2023_zoids.png" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="YearWrap">
                    <div className="YearContent">
                      <div className="TextWrap">
                        <h1>2022</h1>
                        <ul>
                          <li><span>01</span>{props.translation('about_actgames_history2022_01')}</li>
                        </ul>
                      </div>
                    </div>
                    <div className="YearImg">
                      <img  className="Heightimg" src="https://data.actgames.co.kr/actgames/images/about/mogmog.png" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="YearWrap">
                    <div className="YearContent">
                      <div className="TextWrap">
                        <h1>2021</h1>
                        <ul>
                          <li><span>12</span>{props.translation('about_actgames_history2021_12')}</li>
                        </ul>
                      </div>
                    </div>
                    <div className="YearImg ">
                      <img className="Heightimg" src="https://data.actgames.co.kr/actgames/images/about/bubs.png" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="YearWrap">
                    <div className="YearContent">
                      <div className="TextWrap">
                        <h1>2020</h1>
                        <ul>
                          <li><span>12</span>{props.translation('about_actgames_history2020_12')}</li>
                          <li><span>07</span>{props.translation('about_actgames_history2020_07')}</li>
                        </ul>
                      </div>
                    </div>
                    <div className="YearImg">
                      <img className="Heightimg" src="https://data.actgames.co.kr/actgames/images/about/2.png" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="YearWrap">
                    <div className="YearContent">
                      <div className="TextWrap">
                        <h1>2019</h1>
                        <ul>
                          <li><span>05</span>{props.translation('about_actgames_history2019_05')}</li>
                        </ul>
                      </div>
                    </div>
                    <div className="YearImg">
                      <img  className="Heightimg" src="https://data.actgames.co.kr/actgames/images/about/act.png" />
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
{/* 
          <div className="Advisory">
            <div className="AdvisoryWrap">
              <h2>Advisory</h2>
              <div className="AdvisoryList">

                <div className="Member">
                  <div className="ImgWrap">
                    <img src="https://data.actgames.co.kr/actgames/images/about/sejinjung.png" />
                  </div>
                  <div className="MemberContent">
                    <h6>Senior Advisor</h6>
                    <h4>Seijin Jung</h4>
                    <div className="DecWrap">
                      <p>Currently, Seijin Jung is CEO and Vice Chairman of Daily Food Holdings, which co-invests with Anchor Equity Partners and Nepstone Holdings. Daily Food Holdings focused on food production including mushrooms (Daehung Nongsan), yogurts (Yozm), kimchi (Hwami), and food additives (Hyunjin Greemeal), boasting a cumulative AUM of around 610 Million USD. The company also works with food distribution start-ups such as Marketboro, which was co-invested by CJ Group. Recently, Daily Food Holdings has exited their mushroom production company—the largest</p>
                    </div>
                    <div className="BtnWrap">
                      <button onClick={() => { setIsShowSJ(true); }}>READ MORE</button>
                    </div>
                  </div>
                </div>

                <div className="Member">
                  <div className="ImgWrap">
                    <img src="https://data.actgames.co.kr/actgames/images/about/ch.png" />
                  </div>
                  <div className="MemberContent">
                    <h6>Senior Advisor</h6>
                    <h4>CH Park</h4>
                    <div className="DecWrap">
                      <p>Chonghwan (CH) is founder and managing partner of Sapientia Equity Partner, a Korea based PE firm specializes in cross border deals. CH is also working with Vietnam Investment Group (VIG) as a deal &amp; exit partner with roles to support VIG with Korean angles covering both Korea and Vietnam Prior to SEP and VIG, CH was based in Seoul as the CEO of Korea and Asia for MPC Capital, German-listed alternative asset management company. He was responsible for both in and outbound investment opportunities that primarily cover sectors including real estate, infrastructure and shipping.</p>
                    </div>
                    <div className="BtnWrap">
                      <button onClick={() => { setIsShowCH(true); }}>READ MORE</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="Contact">
            <div className="ContactWrap">
              <h6>Contact</h6>
            
              <div className="InfoWrap">
                <div className="BoxWrap BorderRed">   
                  <div className="TextWrap">
                  <h4 className="PointRed">Recruiting</h4>
                  <p>채용 절차 문의</p>
                  </div>
                  <div className="Line">
                  <Link to="mailto:career@actgames.co.kr"><span className="PointRed">career</span>@actgames.co.kr</Link>
                  </div>
                </div>
                <div className="BoxWrap BorderYellow">
                  <div className="TextWrap">
                  <h4 className="PointYellow">Business</h4>
                  <p>사업 | 제휴 | IR</p>
                  </div>
                  <div className="Line">
                  <Link to="mailto:business@actgames.co.kr"><span className="PointYellow">business</span>@actgames.co.kr</Link>
                  </div>
                </div>
                <div className="BoxWrap BorderBlue">
                  <div className="TextWrap">
                  <h4 className="PointBlue">Others</h4>
                  <p>기타 문의</p>
                  </div>
                  <div className="Line">
                  <Link to="mailto:contact@actgames.co.kr"><span className="PointBlue">contact</span>@actgames.co.kr</Link>
                  </div>
                </div>
              </div>
              <div className="InfoTextWrap">
                <h6>{props.translation('Ceo')}</h6>
                <p>{props.translation('OfficeAddress')}</p>
              </div>
            </div>
            <div className="BGPattern100"></div>
          </div>
        </div>
      </section>

      {/* {isShowSJ && <div className="DviewWrapBg SJ">
        <div className="DviewWrap">
          <button className="CloseBtn" onClick={() => { setIsShowSJ(false); }}></button>
          <div className="ContentWrap">
            <img src="https://data.actgames.co.kr/actgames/images/about/sejinjung_c.png" />
            <h6>Senior Advisor</h6>
            <h4>Seijin Jung</h4>
            <div className="DviewDecWrap">
              <p>Currently, Seijin Jung is CEO and Vice Chairman of Daily Food Holdings, which
                co-invests with Anchor Equity Partners and Nepstone Holdings. Daily Food Holdings
                focused on food production including mushrooms (Daehung Nongsan), yogurts (Yozm),
                kimchi (Hwami), and food additives (Hyunjin Greemeal), boasting a cumulative AUM of
                around 610 Million USD. The company also works with food distribution start-ups such
                as Marketboro, which was co-invested by CJ Group. Recently, Daily Food Holdings has
                exited their mushroom production company—the largest in the world—and is looking for
                further exit opportunities.<br /><br />
                Also, Seijin is the founder and Vice Chairman of Nepstone PE and holdings, focusing on
                acquisitions and venture capital investments in Korea. He has invested in various startups
                including Newploy (Automation), MarketBoro (Distribution), Toss (FinTech), NPixel
                (Video Games), DoctorNow (Telemedicine), Fild (Delivery), Mintable (Web3), Scatter
                Lab (AI), Flint Games (Video Games), etc.<br /><br />
                Seijin is a 20-year CEO, a veteran in M&A and company management in various sectors.
                Prior to Daily Food Holdings, Seijin served as CEO for 4-years at Kyungnam Energy, a
                natural gas distributor. Seijin privatized the former Korean Stock Exchange (KSE) listed
                company. Kyungnam Energy netted a massive increase in valuation. By the time it was
                sold to Prostar Global Capital, Kyungnam Energy was rated as the best performing gas
                distributor in Korea.<br /><br />
                Before trying his hand in the energy sector, Seijin was CEO for Mybi, which pioneered
                the Korean transportation card business, creating a nation-wide transit system allowing
                for easy, debit payment on taxis, buses, subways, and stationary stores. Mybi is a similar
                company to Hong Kong’s Octopus. Simultaneously, Seijin acted as CEO for a similar
                company called Busan Hanaro. Both companies were sold at a superb valuation to the
                Lotte Group.<br /><br />
                Prior to Mybi, Seijin accumulated M&A and strategic planning experience in
                entertainment, cable TV, bio, pharmaceuticals, Oil, and construction. Every company
                Seijin has managed has enjoyed a great increase in valuation, giving Seijin a great
                reputation for company management. Seijin was even part of the team that for the first in
                Korea successfully executed a white knight defense for a public company.<br /><br />
                Seijin holds a Masters in Global Business Economics. He received recognition for best
                paper, writing about M&A while at Busan National University. Seijin also holds a MBA
                from Yonsei University</p>
            </div>
          </div>


        </div>
      </div>}
      {isShowCH && <div className="DviewWrapBg CH">
        <div className="DviewWrap">
          <button className="CloseBtn" onClick={() => { setIsShowCH(false); }}></button>
          <div className="ContentWrap">
            <img src="https://data.actgames.co.kr/actgames/images/about/ch_c.png" />
            <h6>Senior Advisor</h6>
            <h4>CH Park</h4>
            <div className="DviewDecWrap">
              <p>Chonghwan (CH) is founder and managing partner of Sapientia Equity Partner, a Korea
                based PE firm specializes in cross border deals.<br /> <br />
                CH is also working with Vietnam Investment Group (VIG) as a deal & exit partner with
                roles to support VIG with Korean angles covering both Korea and Vietnam
                Prior to SEP and VIG, CH was based in Seoul as the CEO of Korea and Asia for MPC
                Capital, German-listed alternative asset management company. He was responsible
                for both in and outbound investment opportunities that primarily cover sectors
                including real estate, infrastructure and shipping.<br /><br />
                Formerly, CH served his dual roles as the Head of Korea for Cobalt, a regional PE Fund
                based in HK, and led the deal origination. Covering both NE and SE Asian countries,
                CH has developed and penetrate various markets that led to significant investment
                opportunities.<br /><br />
                Before Cobalt, CH was the head of Corporate/Structure Finance for GE Capital
                International, where he originated and advised on cross-border debt and equity deals
                involving Korean conglomerates.<br /><br />
                Prior to GE, CH was the Chief Executive of Rabobank Korea, opening the group’s Korea
                office, developing their country strategy and establishing strong networks in both the
                private and public sectors. At Rabobank, he also planned and initiated the group’s
                private equity fund and provided senior advisory services to major Korean companies,
                PE firms, and Korean government divisions and municipalities.<br /><br />
                He also served on the board of Kyungnam Energy, a KSE listed company and he is
                currently a board member of Hanil Feed (KOSDAQ listed) and member of management
                board of VIG (Vietnam Investment Group), one of the largest VN-focused PEFs. CH
                serves as an adjunct lecturer at Seoul National University Business School, has
                published three business books and was a columnist for the Korea Economic Daily.<br /><br />
                CH holds a Bachelors in Economics from Seoul National University, a MSc in Int'l Trade
                from the Graduate School of International Studies, Korea University. CH also
                completed MSc in Finance from London Business School and Ph.D. in Business
                Administration from Kyonggi University</p>
            </div>
          </div>


        </div>
      </div>} */}

    </>
  );
}

export default About;
