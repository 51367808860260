import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { RecoilRoot } from 'recoil';
import reportWebVitals from './reportWebVitals';
import i18n from "./language/LangConfig";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RecoilRoot>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </RecoilRoot>
);
reportWebVitals();
