import React, { useEffect, useState, useRef } from "react";
import { useParams } from 'react-router-dom';
import '../css/itemprobability.css';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import ScrollToHashElement from "../common/ScrollToHashElement";

function csvToJson(text) {
  const [keys, ...rows] = text.split(/\r\n|\n/).map((v) => v.split(","));
  //  console.log(text);
  const json = rows.map((row) => {
    return row.reduce((acc, cur, i) => {
      acc[keys[i]] = isNaN(Number(cur)) ? cur : Number(cur);
      return acc;
    }, {});
  });
  return json;
}

async function getProbabiliyData(gameName) {
  const csv = await fetch("/csv/Web_probability.csv");
  const text = await csv.text();
  const data = csvToJson(text)
    .filter((v) => v.project === gameName)
    .reduce((acc, cur, i) => {
      const index = cur.section - 1;
      acc[index] = [...(acc[index] || []), cur];
      return acc;
    }, [])
    .reduce((acc, cur) => {
      const sectionName = cur[0].sectionName;
      const groupName = cur[0].subName;
      const total = cur.reduce((acc, cur) => acc + cur.probabiliy, 0);
      const findedSection = acc.find((v) => v.sectionName === sectionName);
      const items = cur.map((v) => ({
        id: v.item,
        name: v.name,
        quantity: v.qty,
        probabiliy: Math.floor(((v.probabiliy / total) * 100) * 1000) / 1000,
      }))

      if (!findedSection) {
        const section = {
          sectionName: sectionName,
          groups: [
            {
              groupName: cur[0].subName,
              items: items,
            },
          ],
        };
        return [...acc, section];
      }
      findedSection.groups.push({
        groupName: groupName,
        items: items,
      });
      return acc;
    }, []);
  return data ;
}

function ItemProbability() {

  const params = useParams();
  const [data, setData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    //pekopop
    //aggretsuko
    //bubspuzzleblast
    //mogmogplanet
    console.log("params :" + params.GameName);
    // console.log(location.pathname);
    // console.log(location.hash);
 
    (async () => {
      setData(await getProbabiliyData(params.GameName));
    })()
  }, []);

  return (
    <>
      <div className={"ItemProbabilitySection " + params.GameName}>
        <div className="ItemProbabilitySectionWrap">
          {data.length && (
            <ScrollToHashElement />
          )}
          {data.map((section, i) => (
            <>
              <div className="ItemSection" id={"Section" + (i+1)} >
                <div className="SectionTitle">
                  <h4>{section.sectionName}</h4>
                </div>
                <div className="HeaderItemInfo">
                  <div className="HeaderItem">
                    <h5>이름</h5>
                  </div>
                  {/* <div className="HeaderCount">
                    <h5>갯수</h5>
                  </div> */}
                  <div className="HeaderPercentage">
                    <h5>확률</h5>
                  </div>
                </div>

                {section.groups.map((group) => (
                  <div className="SubSection">
                    {group.groupName !== 0 && <span>{group.groupName}</span>}
                    {group.items.map((item, i) => (
                      <div key={i} className="BodyItemInfo">
                        <div className="BodyItem">
                          <h6>{item.name}<p>x{item.quantity}</p></h6>
                        </div>
                        {/* <div className="BodyCount">
                          <h6></h6>
                        </div> */}
                        <div className="BodyPercentage">
                          <h6>{`${item.probabiliy}%`}</h6>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </>
          ))}

        </div>
      </div>
    </>
  );
}

export default ItemProbability;
