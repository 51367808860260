import  React,{useRef,useEffect} from "react";
import Globe from 'react-globe.gl';
import {countries} from './countries'




function GlobeComponent() {
  const globeEl = useRef();
  const MAP_Start = { lat: 18, lng: 18, altitude: 1.8};
  const MAP_End = { lat: 18, lng: 18, altitude: 1.8 };

  useEffect(() => {
    globeEl.current.controls().enabled = false;
    globeEl.current.controls().autoRotate = true;
    globeEl.current.controls().autoRotateSpeed = -0.3;
    globeEl.current.pointOfView(MAP_Start,1);
    globeEl.current.pointOfView(MAP_End, 3000);
    resizeEvent();
  }, []);

  function resizeEvent() {
    const camera = globeEl.current.camera();
    const render = globeEl.current.renderer();
    window.addEventListener('resize', () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      render.setSize(window.innerWidth, window.innerHeight);
    });
  }



return<>
    <Globe 
ref={globeEl}
width={window.innerWidth}
height={window.innerHeight}
hexPolygonsData={countries.features}
// hexPolygonResolutio={3}
hexPolygonMargin={0.71}
// hexPolygonColor={() =>"rgba(255,80,96, 1)"}
hexPolygonColor={() =>['#f0606d', '#ffc835', '#51c4e4'][Math.round(Math.random() * 3)]}
showGlobe={false}
showAtmosphere={false}
backgroundColor={"rgba(0,0,0,0)"}    
/>;
</>
}
export default GlobeComponent