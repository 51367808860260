import React, {useEffect , useState} from "react";
import '../css/newsdetail.css';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
function NewsDetail(props) {

  const navigate = useNavigate();
  const [IsShowNewsState, setIsShowNews] = useState(false);
  const [targethMap, setTargetMap] = useState([]); 
  const location = useLocation();

  const newsLangTitle = "title_" + document.documentElement.lang;
  const newsLangDec = "Dec_" + document.documentElement.lang;

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    fetchNewsData('/json/news.json');
  }, []);

  const fetchNewsData = (url) => {
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const newsTargetData = data.newsdata;
        console.log(newsTargetData);
        function isTargetNews(element)  {
          if(element.pathURL === location.pathname)  {
            return true;
          }
        }
        const targetNews = newsTargetData.filter(isTargetNews);
        // setPathMap(newsMap);
        setTargetMap(targetNews);
        setIsShowNews(true);
        if(targetNews[0] == null){
          navigate("/notfound");
        }
      })
      .catch(err => {

      });
    ;
  };


  return (
    <section className="NewsDetailSection">
      <div className="SectionHeaderWrap">
        <div className="BGPattern100"></div>
        <div className="MovieScreen"></div>
      </div>
      <div className="NewsDetailSectionWrap">
        {IsShowNewsState &&
          <>
            {targethMap.map(data => {
              const newsDetailView = <div key={data.pathURL} className="NewsDetailWrap">
                <span>{data.date}</span>
                <h5>{data[newsLangTitle]}</h5>
                <div className="DetailImageWrap">
                  <img src={data.thumbImgURL} />
                </div>
                <div className="TextWrap">
                  <p>{data[newsLangDec]}</p>
                </div>
              </div>
              return newsDetailView;
            })}
          </>
        }
        <div className="BtnWrap">
          <Link to="/news">MORE NEWS</Link>
        </div>
      </div>
    </section>
  );
}

export default NewsDetail;
