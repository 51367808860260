import React, {forwardRef, useRef, useState , useEffect } from 'react';
import '../css/home.css';
import i18n from "i18next";
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/pagination';
import { Autoplay, EffectCards } from 'swiper/modules';
import GlobeComponent from "../globe/GlobeComponent";

import { Canvas, useFrame } from '@react-three/fiber'
import { Points, PointMaterial, useTexture, MeshWobbleMaterial} from '@react-three/drei'
import * as random from 'maath/random/dist/maath-random.esm'


import * as THREE from 'three'
import { EffectComposer, GodRays, Bloom ,TiltShift2, HueSaturation, DotScreen } from '@react-three/postprocessing'


function Home(props) {

  const [isShowVideoModal,setVideoModal ] = useState(false);
  const [IsShowNewsAllState, setIsShowNewsAll] = useState(false);

  const swiperSpeed = 1500;
  const autoPlayDelay = 1000;

  // postions를 key로 정렬한 데이터
	// const [positionMap, setPostionMap] = useState({}); 
	 // 원본 데이터
	const [data, setData] = useState([]);

  const newsLang = "title_" + document.documentElement.lang;
  console.log("aa : " + newsLang);

	useEffect(() => {
		document.documentElement.scrollTop = 0;
		fetchNewsData('./json/news.json');
	  }, []);
	
	  const fetchNewsData = (url) => {
		fetch(url)
		  .then((response) => response.json())
		  .then((data) => {
			const news = data.newsdata;
      const all5 =  news.slice(0, 2);
			setData(all5);
			setIsShowNewsAll(true);
		  })
		  .catch(err => {
	
		  });
		;

	  };

    function Stars(props) {
      const ref = useRef()
      const [sphere] = useState(() => random.inCircle(new Float32Array(400), { radius: 2 }))
      useFrame((state, delta) => {
        ref.current.rotation.x -= delta / 30
        ref.current.rotation.y -= delta / 40
      })
      return (
        <group rotation={[0, 0, Math.PI / 4]}>
          <Points ref={ref}  positions={sphere} stride={3} frustumCulled={false} {...props}>
          <PointMaterial transparent color="#fff" size={0.016} sizeAttenuation={true} depthWrite={false} />
        </Points>
        </group>
      )
    }
        
    const poi = useRef()
    
    const Sticker = forwardRef(({ url, ...props }, ref) => {
      const [logo] = useTexture(['Sticjer_1024x1024@2x.png'])
      return (
        <mesh ref={ref} {...props}>
          <planeGeometry args={[1, 1, 32, 32]} />
          <MeshWobbleMaterial
            factor={0.4}
            speed={0.2}
            transparent
            map={logo}
            side={THREE.DoubleSide}
            colorSpace={THREE.SRGBColorSpace} 
          />
        </mesh>
      )
    })


  
  return (
    <>
    <section className="SectionMain">
      <div className="StarsCanvas">
      <Canvas camera={{ position: [0, 0, 1] }} gl={{ antialias: true }}>
      <ambientLight />
      <Stars />
      <Sticker position={[0, -1.3, -1.7]} scale={2} ref={poi} />
      <EffectComposer disableNormalPass multisampling={1}>
              <Bloom luminanceThreshold={0.1} mipmapBlur luminanceSmoothing={0.0} intensity={1} />
            </EffectComposer>
    </Canvas>
      </div>

      <div className="SectionMainWrap">
        <div className="ContentsWrap">
          {/* <h5>{props.translation('home_title1')}</h5>
          <h5>{props.translation('home_title2')}</h5>
          <h5>{props.translation('home_title3')}</h5> */}
          <h2>{props.translation('home_title1')}</h2>
          {/* <h6>유명 IP작품의 감동이 게임에 깊게 스며들어 있는 컨텐츠를 제공하고자 합니다.<br/> 원작의 재미가 게임 컨텐츠로 재 탄생되도록 연구와 노력을 멈추지 않습니다.<br/> 원작과 게임 컨텐츠가 함께 성장할 수 있는 목표를 추구합니다.</h6> */}
          <button className="VideoPlayBtn" onClick={() => {setVideoModal(true);}}></button>
        </div>
        <div className="GlobeWrap">
        <GlobeComponent width="100vw" height="100vh"/>
        
      </div>
      </div>
    </section>
      <section className="UpcomingSection">
    
        <div className="UpcomingWrap">
        <h2>{props.translation('upcoming')}</h2>
        <div className="GamesWrap">
        <div className="Game">
            <div className="TitleWrap">
              <img src="https://data.actgames.co.kr/actgames/images/home/hk50.png" />
              <div className="TextWrap">
                <h6>{props.translation('upcominggame1_title')}</h6>
                <p>{props.translation('upcominggame1_dec')}</p>
              </div>
            </div>
            <img src="https://data.actgames.co.kr/actgames/images/home/hk.png" />
          </div>

          <div className="Game">
            <div className="TitleWrap">
              <img src="https://data.actgames.co.kr/actgames/images/home/tf40.png" />
              <div className="TextWrap">
                <h6>{props.translation('upcominggame2_title')}</h6>
                <p>{props.translation('upcominggame2_dec')}</p>
              </div>
            </div>
            <img src="https://data.actgames.co.kr/actgames/images/home/tf.png" />
          </div>
        </div>
 
        </div>
      </section>
      <section className="MainGamesSection">
        <div className="MainGamesSectionWrap">
          <div className="MainGamesSwiper">
            <div className="MainGamesSwiperWrap">
              <Swiper
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                effect={'cards'}
                grabCursor={true}
                loop={true}
                speed={800}
                modules={[Autoplay, EffectCards]}
                className="CardSwiper"
              >
                <SwiperSlide><img src="https://data.actgames.co.kr/actgames/images/games/512_zoids.png" /></SwiperSlide>
                <SwiperSlide><img src="https://data.actgames.co.kr/actgames/images/games/512_pub.png" /></SwiperSlide>
                <SwiperSlide><img src="https://data.actgames.co.kr/actgames/images/games/512_agg.png" /></SwiperSlide>
                <SwiperSlide><img src="https://data.actgames.co.kr/actgames/images/games/512_peko.png" /></SwiperSlide>
                <SwiperSlide><img src="https://data.actgames.co.kr/actgames/images/games/512_mogmog.png" /></SwiperSlide>
              </Swiper>
            </div>
            <div className="TextWrap">
              <h2>{props.translation('Games')}</h2>
              <h6>{props.translation('home_games_dec')}</h6>
              <div className="BtnWrap">
                <Link to="/games">{props.translation('seegames')}</Link>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section className="SectionMainNews">
      <div className="SectionMainNewsWrap">
        <div className="NewsTitleWrap">
          <h2>{props.translation('news')}</h2>
          <Link to="/news">{props.translation('ViewMore')}<img className="RightArrow"/></Link>
        </div>
          <div className="NewsContentWrap">
            {IsShowNewsAllState &&
              <>
                {data.map(data => {
                  const list = <Link to={data.pathURL} key={data.pathURL} className="NewsWrap">
                    <div className="imgWrap">
                      <img src={data.thumbImgURL} />
                    </div>
                    <div className="TextWrap">
                      <p className="Date">{data.date}</p>
                      <h5 className="NewsTitle">{data[newsLang]}</h5>
                    </div>
                  </Link>
                  return list;
                })}
              </>
            }
          </div>
      </div>
    </section>

      <section className="Partners">
        <div className="PartnersWrap">
          <h2>Partners with us</h2>
        </div>
        <div className="PartnersTopSwiperWrap">
            <Swiper
              centeredSlides={true}
              loop={true}
              speed={swiperSpeed}
              allowTouchMove={false}
              autoplay={{
                delay: autoPlayDelay,
                disableOnInteraction: false,
              }}
              spaceBetween={30}
              modules={[Autoplay]}
              
              breakpoints={{
                310: { slidesPerView: 2 }, 470: { slidesPerView: 2 }, 630: { slidesPerView: 3 }, 1110: { slidesPerView: 3 }, 1430: { slidesPerView: 4 }, 1750: { slidesPerView: 5 }, 2550: { slidesPerView: 5 }
              }}
              className="TopSwiper"
            >
              <SwiperSlide><img src="https://data.actgames.co.kr/actgames/images/home/p01.png" /></SwiperSlide>
              <SwiperSlide><img src="https://data.actgames.co.kr/actgames/images/home/p02.png" /></SwiperSlide>
              <SwiperSlide><img src="https://data.actgames.co.kr/actgames/images/home/p03.png" /></SwiperSlide>
              <SwiperSlide><img src="https://data.actgames.co.kr/actgames/images/home/p04.png" /></SwiperSlide>
              <SwiperSlide><img src="https://data.actgames.co.kr/actgames/images/home/p05.png" /></SwiperSlide>
              <SwiperSlide><img src="https://data.actgames.co.kr/actgames/images/home/p01.png" /></SwiperSlide>
              <SwiperSlide><img src="https://data.actgames.co.kr/actgames/images/home/p02.png" /></SwiperSlide>
              <SwiperSlide><img src="https://data.actgames.co.kr/actgames/images/home/p03.png" /></SwiperSlide>
              <SwiperSlide><img src="https://data.actgames.co.kr/actgames/images/home/p04.png" /></SwiperSlide>
              <SwiperSlide><img src="https://data.actgames.co.kr/actgames/images/home/p05.png" /></SwiperSlide>
              <SwiperSlide><img src="https://data.actgames.co.kr/actgames/images/home/p01.png" /></SwiperSlide>
              <SwiperSlide><img src="https://data.actgames.co.kr/actgames/images/home/p02.png" /></SwiperSlide>
              <SwiperSlide><img src="https://data.actgames.co.kr/actgames/images/home/p03.png" /></SwiperSlide>
              <SwiperSlide><img src="https://data.actgames.co.kr/actgames/images/home/p04.png" /></SwiperSlide>
              <SwiperSlide><img src="https://data.actgames.co.kr/actgames/images/home/p05.png" /></SwiperSlide>
            </Swiper>
          </div>

      </section>


      {/* <section className="MainCareerSection">
        <div className="MainCareerSectionWrap">
          <div className="MainCareerWrap">
            <div className="TextWrap">
              <h2>{props.translation('careers')}</h2>
              <h6>{props.translation('home_careers_dec')}</h6>
              <div className="BtnWrap">
                <Link to="/careers">{props.translation('seecareers')}</Link>
              </div>
            </div>
            <div className="ImageWrap">
              <img src="https://data.actgames.co.kr/actgames/images/home/win.png" />
            </div>
          </div>
        </div>
      </section> */}
      {isShowVideoModal && <div className="VideoModal">
        <video className="ModalVideo" controls autoPlay>
            <source src="https://data.actgames.co.kr/actgames/images/home/actgames_low.mp4" type="video/mp4"></source>
        </video>
        <button className="CloseBtn" onClick={() => { setVideoModal(false); }}></button>
    </div>}
    </>
  );
}

export default Home;
