import React, {useEffect , useState} from "react";
import '../css/news.css';
import { Link } from 'react-router-dom';

function News(props) {

  const [IsShowNewsAllState, setIsShowNewsAll] = useState(false);

  // postions를 key로 정렬한 데이터
	// const [positionMap, setPostionMap] = useState({}); 
	 // 원본 데이터
	const [data, setData] = useState([]);

  const newsLangTitle = "title_" + document.documentElement.lang;
  const newsLangDec = "Dec_" + document.documentElement.lang;
  const newsLang = "title_" + document.documentElement.lang;

	useEffect(() => {
		document.documentElement.scrollTop = 0;
		fetchNewsData('./json/news.json');
	  }, []);
	
	  const fetchNewsData = (url) => {
		fetch(url)
		  .then((response) => response.json())
		  .then((data) => {
			const news = data.newsdata;

			setData(news);
			setIsShowNewsAll(true);
		  })
		  .catch(err => {
	
		  });
		;
	  };


  return (
    <section className="NewsSection">
      <div className="SectionHeaderWrap">
        <div className="BGPattern100"></div>
        <div className="MovieScreen"></div>
      </div>
      <div className="NewsSectionWrap">
        <h4>News</h4>
        <div className="NewsListWarp">
          {IsShowNewsAllState &&
              <div className="NewsGrid">
              {data.map(data => {
                const list = <Link to={data.pathURL} key={data.pathURL} className="ContentWrap">
                  <div className="ImageWrap">
                    <img src={data.thumbImgURL} />
                  </div>
                  <div className="TextWrap">
                    <div className="PaddingWrap">
                      <span>{data.date}</span>
                      <h5>{data[newsLangTitle]}</h5>
                      {/* <p>{data[newsLangDec]}</p> */}
                    </div>
                  </div>
                </Link>
                return list;
              })}
            </div>
          }
        </div>
      </div>
    </section>
  );
}

export default News;
