import React from 'react';
import { Link } from 'react-router-dom';
// import '../css/header.css';
import '../css/header_work.css';
import { Translation } from "react-i18next";
import ChangeLang from "../language/ChangeLang";
import { useEffect } from 'react';




function Header(props) {

  const headerCheckBox = document.getElementById("Nav");

  function CloseMobileMenu() {
    headerCheckBox.checked = false;
  }

window.onscroll = function () {
  scrollChangeColorFunction();
};

function scrollChangeColorFunction() {

  if (document.documentElement.scrollTop < 200) {
    document.getElementById("HeaderBgColor").style.opacity = '0';
  } else {
    document.getElementById("HeaderBgColor").style.opacity = '0.9';
  }
}
    return (
      <header className="HeaderWrap">
        <div className="NavWrap">
          <span id="HeaderBgColor"></span>
          <Link to="/" className="Hlogo" onClick={() => { CloseMobileMenu() }}><img src="https://data.actgames.co.kr/actgames/images/common/actgames_logo.png" alt="Logo" /></Link>
          <input type="checkbox" id="Nav" className="hidden" />
          <label htmlFor="Nav" className="NavOpen"><i></i><i></i><i></i></label>
          <div className="NavContainer">
            <div className="NavContainerWrap" id="HeaderMenu">
              <ul>
                <li>
                  <div className="Dropdown">
                  <Link to="/games" className="Dropbtn" onClick={() => { CloseMobileMenu() }}>Games</Link>
                    <div className="DropDownContent">
                      <Link to="/games/zoidswildarea" className="MenuBtn" onClick={() => { CloseMobileMenu() }}><img className="AppIcon Zoids"/>ZOIDS WILD ARENA</Link>
                      <Link to="/games/bubspuzzleblast" className="MenuBtn" onClick={() => { CloseMobileMenu() }}><img className="AppIcon Bubs"/>Bub's Puzzle Blast</Link>
                      <Link to="/games/aggretsuko" className="MenuBtn" onClick={() => { CloseMobileMenu() }}><img className="AppIcon Aggre"/>Aggretsuko</Link>
                      <Link to="/games/pekopop" className="MenuBtn" onClick={() => { CloseMobileMenu() }}><img className="AppIcon Peko"/>Peko Pop</Link>
                      <Link to="/games/mogmog" className="MenuBtn" onClick={() => { CloseMobileMenu() }}><img className="AppIcon MogMog"/>Mogmog Planet</Link>
                      <Link className="MenuBtn Disabled Flex"><img className="AppIcon Transformers"/><div><p>Transformers Project</p><span>Coming Soon</span></div></Link>
                      <Link className="MenuBtn Disabled Flex"><img className="AppIcon Kity"/><div><p>Hello Kitty project</p><span>Coming Soon</span></div></Link>
                    </div>
                  </div>
                </li>
                <li><Link to="/about" className="MenuBtn" onClick={() => { CloseMobileMenu() }}>About</Link></li>
                <li><Link to="/news" className="MenuBtn">News</Link></li>
                {/* <li><Link to="/careers" className="MenuBtn" onClick={() => { CloseMobileMenu() }}>Careers</Link></li> */}
              </ul>
            </div>
            <div className="LanguageButtonWrap">
            <Translation>{t => <ChangeLang t={t} />}</Translation>
        </div>
          </div>
        </div>
        
        <div className="ColorLine"></div>
      </header>
    );
}

export default Header;
